<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="signUpObserver" @submit.prevent>
      <NTypeform v-if="!displayRelog" :currentSlide="currentSlide">
        <template v-slot:slides>
          <NTypeformSlide :index="1">
            <template>
              <span class="text-white font-latoBold">{{$t('register.form.gender_label')}}</span>
              <NRadios
                data-cy="input-civility"
                id="iCivilId"
                :name="$t('register.form.gender').toLowerCase()"
                rules="required"
                v-model="signupForm.model.iCivilId"
                :displayError="false"

                textColor="white"
                selectedColor="black"
                radioColor="white"
                :borderColor="colorStyle.quinary"
                selectedBackgroundColor="#FFD400"
                backgroundColor="transparent"
                :designType="panoplyStyle.inputs.designType"
                :values="{'M.': 1, 'Mme': 2}"
                errorColor="#ff3b30"
                @isValid="(isValid) => {validity.gender = isValid; nextSlide();}"
              ></NRadios>
              <!-- <NTypeformCta v-if="validity.gender" @click.native="nextSlide()">Ok</NTypeformCta> -->
            </template>
          </NTypeformSlide>

          <NTypeformSlide :index="2">
            <template>
              <NText
                data-cy="input-firstname"
                id="input-firstname"
                :name="$t('register.form.first_name').toLowerCase()"
                type="text"
                v-model="signupForm.model.sFirstName"
                rules="required|nameType"
                animationType="line"
                :designType="panoplyStyle.inputs.designType"
                errorColor="#ff3b30"
                :backgroundColor="colorStyle.quinary"
                placeholder="Ex: Jean"
                errorTrigger="blur"
                @isValid="(isValid) => (validity.firstname = isValid)"
              >{{$t('register.form.first_name_label')}}</NText>
              <NTypeformCta v-if="validity.firstname" @click.native="nextSlide()">Ok</NTypeformCta>
            </template>
          </NTypeformSlide>

          <NTypeformSlide :index="3">
            <template>
              <NText
                data-cy="input-lastname"
                id="input-lastname"
                :name="$t('register.form.last_name').toLowerCase()"
                type="text"
                v-model="signupForm.model.sLastName"
                rules="required|nameType"
                animationType="line"
                :designType="panoplyStyle.inputs.designType"
                :borderColor="colorStyle.primary"
                errorColor="#ff3b30"
                errorTrigger="blur"
                :backgroundColor="colorStyle.quinary"
                placeholder="Ex: Dupont"
                @isValid="(isValid) => (validity.lastname = isValid)"
              >{{$t('register.form.last_name_label')}}</NText>
              <NTypeformCta v-if="validity.lastname" @click.native="nextSlide()">Ok</NTypeformCta>
            </template>
          </NTypeformSlide>

          <NTypeformSlide ref="emailSlide" :index="4">
            <template>
              <NText
                data-cy="input-email"
                ref="input-email"
                id="input-email"
                :name="$t('register.form.email').toLowerCase()"
                type="email"
                v-model="signupForm.model.sEmail"
                :rules="`required|email`"
                animationType="line"
                :designType="panoplyStyle.inputs.designType"
                :borderColor="colorStyle.primary"
                errorColor="#ff3b30"
                :backgroundColor="colorStyle.quinary"
                errorTrigger="blur"
                placeholder="Ex: jean@gmail.com"
                @isValid="(isValid) => {(validity.email = isValid); didChangeEmail();$emit('update:user_already_exist', false);}"
              >{{$t('register.form.email_label')}}</NText>
              <span
                v-if="user_already_exist"
                class="block mb-4 text-xs text-error -mt-5"
              >{{$t('register.form.errors.email.already_used')}}</span>
              <NTypeformCta v-if="validity.email" @click.native="nextSlide()">Ok</NTypeformCta>
            </template>
            
          </NTypeformSlide>

          <NTypeformSlide :index="5">
            <template>
              <NMask
                data-cy="input-birthday"
                id="input-birthday"
                :name="$t('register.form.birthday').toLowerCase()"
                maskType="tel"
                mask="##/##/####"
                rules="ageMin:18|required"
                v-model="signupForm.model.oResponses.dBirthDate_1"
                animationType="label"
                labelColor="red"
                errorTrigger="blur"
                :backgroundColor="colorStyle.quinary"
                :designType="panoplyStyle.inputs.designType"
                :borderColor="colorStyle.primary"
                errorColor="#ff3b30"
                placeholder="Ex: 04/01/1988"
                @isValid="(isValid) => {validity.birthday = (isValid); checkAge();}"
              >{{$t('register.form.birthday_label')}}</NMask>
              <NTypeformCta v-if="validity.birthday" @click.native="nextSlide()">Ok</NTypeformCta>
            </template>
          </NTypeformSlide>

          <NTypeformSlide :index="6">
            <template>
              <div v-if="!dedupStatus.isDedup" class="-mb-8">
                <NCheckbox 
                  data-cy="input-optin"
                  id="input-optin"
                  name="optin"
                  textColor="white"
                  :checkmarkColor="colorStyle.quinary"
                  v-model="signupForm.model.oOptins.iOptin_2"
                  :borderColor="colorStyle.primary"
                  errorColor="#ff3b30"
                >{{$t('register.form.iOptin_2_label')}}</NCheckbox> 
              </div>
              <div>
                <NCheckbox 
                  data-cy="input-terms"
                  id="input-terms"
                  name="terms"
                  textColor="white"
                  rules="required|requiredCheckbox"
                  :checkmarkColor="colorStyle.quinary"
                  :borderColor="colorStyle.primary"
                  errorColor="#ff3b30"
                  v-model="signupForm.terms"
                  >{{$t('register.form.terms')}}</NCheckbox> 
              </div>
              <span class="table m-auto">
                <NCta
                  borderRadius="30px"
                  backgroundColor="#FFD400"
                  textColor="#014457"
                  data-cy="submit-register"
                  @click.native="handleSubmit(submit)"
                  designType="round"
                  :disabled="is_loading"
                  ref="form_submit_button"
                >
                <span class="text-md font-oswaldBold uppercase">{{$t('register.submit')}}</span></NCta>
              </span>
            </template>
          </NTypeformSlide>
        </template>

        <template v-slot:navigation>
          <NTypeformNavigation
            :currentSlide="currentSlide"
            :requiredSlides="slides ? slides.length : 0"
            :totalSlides="slides ? slides.length + 1 : 0"
            :completedSlides="Object.values(validity).filter((valid) => valid).length"
            :nextEnabled="validity[slides[currentSlide]]"
            backgroundColor="#d1d1d1"
            @next="nextSlide"
            @prev="prevSlide"
          >
            complété
          </NTypeformNavigation>
        </template>
      </NTypeform>
    </ValidationObserver>
    
    <ValidationObserver
      class="py-4 px-8 mx-auto"
      v-slot="{handleSubmit}"
      ref="relogObserver"
      tag="form"
      @submit.prevent
      v-if="displayRelog" >
      <div v-if="!relogEmailSent">
        <!-- EMAIL -->
        <NText
          data-cy="input-email"
          ref="input-email"
          id="input-email"
          :name="$t('register.form.email').toLowerCase()"
          type="email"
          v-model="relogForm.model.sEmail"
          :rules="`required|email|already_registered:${user_already_exist}`"
          animationType="line"
          errorTrigger="blur"
          :designType="panoplyStyle.inputs.designType"
          :borderColor="colorStyle.primary"
          errorColor="#ff3b30"
          :backgroundColor="colorStyle.quinary"
        >{{$t('register.form.email_label')}}</NText>

        <!-- SUBMIT -->
        <span class="table m-auto mt-12">
          <NCta
            borderRadius="30px"
            backgroundColor="#FFD400"
            textColor="#014457"
            data-cy="submit-register"
            @click.native="handleSubmit(submitRelog)"
            designType="round"
            :disabled="is_loading"
            ref="form_submit_button"
          >
          <span class="text-md font-oswaldBold uppercase">{{$t('register.submit')}}</span></NCta>
        </span>
      </div>
      <div v-else>
        <p class="text-center text-white font-lato mb-12" data-cy="success-message-relog">{{$t('register.relog.description')}}</p>
        <div class="text-center">
          <NCta
            borderRadius="30px"
            backgroundColor="#FFD400"
            textColor="#014457"
            class="mx-auto"
            designType="round"
            @click.native="handleSubmit(submitRelog)"
            :disabled="is_loading"
            ref="form_submit_button"
            data-cy="cta-relog-resend"
          >{{$t('register.relog.resend')}}</NCta>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  NRadios,
  NText,
  NMask,
  NCheckbox
} from '@team-uep/n-inputs'

import {
  NTypeform,
  NTypeformSlide,
  NTypeformCta,
  NTypeformNavigation
} from "@team-uep/n-typeform";

import { mapGetters, mapActions } from 'vuex'
import registerForm from '../mixins/registerForm'
import design from '@/validators/design'

export default {
  name: 'RegisterTypeForm',
  mixins: [registerForm],
  components: { 
    NRadios,
    NText,
    NMask,
    NCheckbox,
    NTypeform,
    NTypeformNavigation,
    NTypeformSlide,
    NTypeformCta
  },
  props: {
    relogEmailSent: Boolean,
    displayRelog: Boolean,
    is_loading: Boolean,
    user_already_exist: Boolean
  },
  data () {
    return {
      colorStyle: design.colors,
      panoplyStyle: design.panoply,
      signupForm: {
        optin: 0,
        terms: null,
        model: {
          oResponses: {
            dBirthDate_1: ''
          },
          iCivilId: '',
          sEmail: '',
          sLastName: '',
          sFirstName: '',
          oOptins: {
            iOptin_2: ''
          }
        }
      },
      relogForm: {
        model: {}
      },
      currentSlide: 0,
      slides: [
        "gender",
        "firstname",
        "lastname",
        "email",
        "birthday"
      ],
      validity: {
        gender: false, //1
        firstName: false, //2
        lastname: false, //3
        email: false, //4
        birthday: false, //5
        terms: false // 7
      }
    }
  },
  computed: {
    ...mapGetters({
      stateParams: 'user/stateParams'
    })
  },
  mounted() { 
    window.addEventListener("keyup", this.pressEnter, false);
    
    // URL params prefill on register form
    const urlParams = this.stateParams || this.$route.query;
    const inputEmailRef = this.$refs['input-email'];

    urlParams.e != null ? this.signupForm.model.sEmail = urlParams.e : null
    urlParams.p != null ? this.signupForm.model.sFirstName = urlParams.p : null
    urlParams.n != null ? this.signupForm.model.sLastName = urlParams.n : null
    urlParams.c != null && (urlParams.c === '1' || urlParams.c === '2') ? this.signupForm.model.iCivilId = parseInt(urlParams.c) : null
  },
  watch: {
    'user_already_exist' (value) {
      if (value) {
        this.goSlide(3);
      }
    }
  },

  destroyed() {
    window.removeEventListener("keyup", this.pressEnter, false);
  },
  methods: {
    ...mapActions(['updateModalConfig']),
    pressEnter(e) {
      if ((e.keyCode === 9 || e.keyCode === 13) &&  this.validity[this.slides[this.currentSlide]]) {
        this.nextSlide();
      }
    },
    checkAge() {
      var value = this.signupForm.model.oResponses.dBirthDate_1
      var ageMin = "18"

      var userDate, splitValue
      if (value.match(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/)) {
        splitValue = value.split('/')
        userDate = new Date(splitValue[2], parseInt(splitValue[1] - 1), splitValue[0])
        var newYear = userDate.getFullYear() + parseInt(ageMin)
        var tempDate = new Date(newYear, userDate.getMonth(), userDate.getDate())
        var isMajor = tempDate <= new Date()
        if (!isMajor) {
          this.updateModalConfig({ isOpen: true, modalComponent: 'UnderageModal', modalClass: 'under-age-modal', param: this.signupForm.model.sFirstName })
        }
      }
    },
    checkSlideAuth(num) {
      const next = Math.min(this.slides.length - 1, Math.max(0, num));
      for (let i = 0; i < next; i++) {
        const slideName = this.slides[i];
        if (!this.validity[slideName]) {
          return false;
        }
      }
      return true;
    },
    goSlide(num) {
      if (this.checkSlideAuth(num) && num <= this.slides.length) {
        this.currentSlide = num;
      } else {
        var form = this.$children[0].$children[0].$children[0]
        var elem = form.$children[this.currentSlide].$children[0]
        if(elem.$refs.inputField) {
          if (this.currentSlide >= 3) {
            window.setTimeout(() => {
              elem.$refs.inputField.$el.focus()
            }, 550)
          } else {
            window.setTimeout(() => {
              elem.$refs.inputField.focus()
            }, 550)
          }
        }
      }
    },
    prevSlide() {
      this.goSlide(this.currentSlide - 1);
    },
    nextSlide() {
      this.goSlide(this.currentSlide + 1);
    },

    submit () {
      this.$emit('submit', this.signupForm);
    },
    submitRelog () {
      this.$emit('submitRelog', this.relogForm);
    }
  }
}
</script>

<style lang="scss" scoped>
.n-input ::v-deep {
  font-family: "Lato bold";
  ::placeholder {
    color: black !important;
    opacity: 0.3;
  }
  input {
    color: #014457 !important;
  }
  .error {
    input  {
      // color: #FF3B30 !important;
    }
  }
  .n-input__container {
    .n-input__label-text {
      margin-bottom: 1.5em !important;
    }
  }
  .n-input__error-icon {
    display: none;
  }
  .n-input__radio {
    border-radius: 5px !important;
    width: 120px;
    height: 50px;
    span {
      color: white;
    }
  }
  .n-input__bottom-area {
    span {
      color: #ff3b30;
    }
  }
  span {
    color: white;
  }
  .n-input__popin {
    width: 57% !important;
    height: 56% !important;
    margin-left: 0px;
  }
  .n-input__radio-label {
    font-family: "Lato Bold";
  }
}
.typeform ::v-deep {
  .slide {
    padding: 0 1.25rem !important;
  }
  .slide.is-state-next-1 {
    opacity: 0 !important;
  }
  .slide-container {
    height: 228px !important;
  }
  .progress-bar {
    .text {
      font-family: "Lato";
      color: white;
    }
    .bar {
      background-color: rgba(255, 255, 255, .15)
    }
    .bar__filler {
      background-color: #FFD400 !important;
    }
  }
  .navigation {
      margin-top: -2rem;
    }
  .navigation-buttons {
    width: 100%;
    .button__img {
      width: 20px;
      &.disabled {

      }
    }
    
    .navigation .navigation-buttons {
      margin-left: -1rem;
      margin-top: 1.5rem;
      .button {
        margin: 0 12px;
      }
    }
  }
}
.n-cta ::v-deep {
  width: 200px;
  padding: 8px 25px !important;
  background-color: #FFD400 !important;
  font-family: "Oswald Bold";
}
.n-typeform-cta ::v-deep {
  margin-top: -5px;
  font-family: "Lato bold";
  display: flex;
  background-color: #06ccc6;
  color: #004557;
  .valid-icon {
    margin-top: 0.5rem;
    fill: #004557;
  }
}
.typeform ::v-deep {
  .navigation-buttons {
    margin-top: 1.5rem;
  }
  .text-error {
    // color: #ff3b30;
  }
  .button__img {
    border-left: 2px solid #FFD400 !important;
    border-top: 2px solid #FFD400 !important;
    opacity: 1 !important;
    &.disabled {
      border-left: 2px solid rgba(255, 255, 255, 0.5) !important;
      border-top: 2px solid  rgba(255, 255, 255, 0.5) !important;
      opacity: 1 !important;
    }
  }
}
::v-deep .n-input.n-text,
.n-input.n-radios,
.n-input.n-checkbox {
  // reset property
  @apply py-3 text-md min-h-0;
}

.n-input__radio-label /deep/{
  padding-right: 25px;
}

.n-checkbox ::v-deep{
  .n-input__checkbox {
    height: 40px;
    max-width: 30px;
    .n-input__checkmark {
      height: 30px; 
      width: 30px;
    }
  }
  .n-input__error-text {
    text-align: center;
  }
  .n-input__checkmark-label {
    font-family: "Lato";
    margin-left: 1rem;
    line-height: 20px;
  }
}

form {
  max-width: 335px;
  margin: auto;
}
</style>
