<template>
  <div class="register-container desktop-container m-auto base__line">
    <div class="desktop-screen relative m-auto">
      <div class="bg-mobile bg-center bg-top pb-5">
        <div class="text-white base__line pt-3">
          <logo-icon></logo-icon>
          <img
            width="230"
            height="135"
            class="m-auto mt-4"
            src="../assets/register/title.jpg"
            alt
            srcset
          />
          <div class="test-button">
          </div>

          <p class="subtitle text-lg font-latoBold text-center px-4 mt-2" v-html="$t('register.subtitle')"></p>
          <section
            class="flex flex-col justify-center h-full mx-8 lg:h-auto lg:p-0"
          >
            <div class="images-container pt-4 mt-2 relative">
              <transition name="crossfade" >
                <div v-if="imageIndex === 1" class="absolute w-full">
                  <img
                    class="m-auto iphone-img"
                    src="../assets/register/iphone@2x.png"
                    alt
                    srcset
                  />
                </div>
              </transition>
              <transition name="crossfade">
                <div v-if="imageIndex === 2" class="absolute w-full">
                  <img
                    class="m-auto"
                    src="../assets/register/spotify@2x.png"
                    alt
                    srcset
                  />
                </div>
              </transition>
              <transition name="crossfade">
                <div v-if="imageIndex === 3" class="absolute w-full">
                  <img
                    class="m-auto"
                    src="../assets/register/billets@2x.png"
                    alt
                    srcset
                  />
                </div>
              </transition>
            </div>
            <div class="nav-button-container flex m-auto -mt-5 mb-4">
              <div class="nav-button" :class="{'active': imageIndex === 1}"></div>
              <div class="nav-button" :class="{'active': imageIndex === 2}"></div>
              <div class="nav-button" :class="{'active': imageIndex === 3}"></div>
            </div>
            <span class="header-button table m-auto mt-2">
              <NCta
                data-cy="submit-register"
                @click.native="goToForm()"
                borderRadius="30px"

                backgroundColor="#FFD400"
                textColor="#014457"

                :disabled="is_loading"
                ref="form_submit_button"
              >
                <span class="text-md font-oswaldBold uppercase">{{$t('register.submit')}}</span>
              </NCta>
            </span>
          </section>
        </div>
        <section
          class="form min-h-full mt-5 mt-32 mx-auto"
          id="form"
        >
          <img
            class="title-img w-full m-auto mt-6 mb-4"
            src="../assets/register/titre_inscrivez_vous@2x.png"
            alt
            srcset
          />
          <register-type-form
            @submit="postRegister"
            @submitRelog="submitRelog"
            :relogEmailSent="relogEmailSent"
            :displayRelog="displayRelog"
            :is_loading="is_loading" 
            @update:user_already_exist="user_already_exist = $event"
            :user_already_exist="user_already_exist"
          />
          
          <a
            @click="displayRelog = !displayRelog; goToForm();"
            data-cy="cta-already-register" 
            class="table mt-4 pb-3 m-auto text-center text-sm font-lato text-white underline cursor-pointer"
            v-html="
              displayRelog
                ? $t('register.not_registered')
                : $t('register.already_registered')
            "
          ></a>
          <p class="text-xs pb-6 text-white font-lato m-auto w-full text-center" v-html="$t('register.mandatory')"></p>
        </section>
      </div>
      <SiteFooter></SiteFooter>
    </div>

    <div class="gaming-bg"></div>

    <div id="captcha">
      <vue-recaptcha
        ref="recaptcha"
        id="captcha_id"
        sitekey="6Lf0uhwUAAAAAAZQK0AfIcp6wMgQNO_EOoFg9zND"
        @verify="onVerify"
        :loadRecaptchaScript="true"
        size="invisible"
      ></vue-recaptcha>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { removeSpaces } from '@/helpers'
  import design from '@/validators/design'
  import RegisterTypeForm from '@/components/RegisterTypeForm.vue'

  import LogoIcon from "@/components/icons/LogoIcon";
  import VueRecaptcha from 'vue-recaptcha'
  export default {
    name: 'register',
    data () {
      return {
        design: design,
        displayRelog: false,
        relogEmailSent: false,
        user_already_exist: false,
        is_loading: false,
        suggestions: [],
        needsSuggestions: false,
        blocked: false,
        imageIndex: 1,
        animationInterval: null
      }
    },
    components: {  
      VueRecaptcha, 
      RegisterTypeForm,
      LogoIcon
    },
    methods: {
      // ...mapActions(['updateUserSession']),
      submitRelog (relogForm) {
        if (!this.relogEmailSent) {
          this.$requestAPI.sendRelogEmail({
            sEmail: relogForm.model.sEmail,
          })
          .catch(console.log)
          .finally(() => {
            this.relogEmailSent = true
            this.is_loading = false
          });
        } else {
          this.relogEmailSent = false
        }
      },

      postRegister (signupForm) {
        this.is_loading = true
        // In case we need to update the data before sending them
        let userData = JSON.parse(JSON.stringify(signupForm.model));
        // Clean data before calling the API
        userData.sFirstName = removeSpaces(userData.sFirstName)
        userData.sLastName = removeSpaces(userData.sLastName)

        if (userData.oOptins.iOptin_2 == "" || userData.oOptins.iOptin_2 == false) {
          delete userData.oOptins.iOptin_2;
        } else if (userData.oOptins.iOptin_2 == true) {
          userData.oOptins.iOptin_2 = 1;
        }

        this.$requestAPI.register(userData)
          .then(() => {
            this.$mmTro.conversion(this.$route.query)

            this.$router.push({ name: this.$route.meta.nextPage })
              .catch(console.log);
          })
          .catch((error) => {
            // Test if error is an ApiError object
            if (error.name === 'ApiError') {
              if (error.response && error.response.status === 503) {
                const onRecaptcha = () => {
                  this.$off("recaptcha-valid", onRecaptcha);
                  this.postRegister(signupForm);
                }

                // trick to keep signupForm in cache
                // create a listenner on recaptcha 
                this.$on("recaptcha-valid", onRecaptcha);

                this.$refs.recaptcha.reset()
                this.$refs.recaptcha.execute()
              }
  
              if (error.response && error.response.status === 409) {
                this.user_already_exist = true
              }
            }
            this.is_loading = false
          });
      },

      goToForm () {
        // window.location.href = '#formulaire'
        var element = document.getElementById("form");
        element.scrollIntoView({behavior: "smooth", inline: "nearest"});
      },

      onVerify: function (response) {
        console.log('Verify: ' + response)
        this.sendCaptchaResult(response)
      },

      sendCaptchaResult (result) {
        this.$requestAPI.sendCaptchaToken({
          sBlockedUrl: [
            `${document.location.protocol}//${document.location.host}/api/v0/`,
            `${this.appConfig.baseName.replace(/[_-]/, '_')}/${this.appConfig.activeDecli.apiVersion}`,
            `/inscription/register`
          ].join(''),
          sCaptchaResponse: result,
        })
          .then(() => {
            this.$emit('recaptcha-valid')
          })
          .catch(console.log);
      }
    },
    computed: {
      ...mapActions(['updateModalConfig']),
      ...mapGetters({
        appConfig: 'config/appConfig'
      }),
    },
    mounted () {
      if (this.animationInterval) {
        clearInterval(this.animationInterval)
      } else {
        this.animationInterval = setInterval(() => {
          if (this.imageIndex === 3) {
            this.imageIndex = 1;
          } else {
            this.imageIndex += 1;
          }
        }, 3000);
      }
      this.$mmTro.sendRtg()
    },
    destroyed () {
      clearInterval(this.animationInterval)
    }
  }
</script>

<style lang="scss" scoped>
@import '../styles/crossfade-transition';
.register-container {
  background-position-y: 0px;
  .images-container {
    height: 10rem;
    img {
      height: 100px;
    }
    .iphone-img {
      height: 140px;
      margin-top: -1rem;
    }
  }
  .subtitle {
    line-height: 23px;
  }
  .nav-button-container {
    .nav-button {
      height: 6px;
      width: 6px;
      margin-right: 6px;
      border-radius: 100%;
      background-color: white;
      opacity: 0.25;
      &.active {
        opacity: 1;
      }
    }
  }
  .header-button {
    .n-cta ::v-deep {
      width: 200px;
      padding: 8px 25px !important;
      background-color: #FFD400 !important;
      font-family: "Oswald Bold";
    }
  }
  .title-img {
    max-width: 300px;
  }
  .form {
    width: 88%;
    min-width: 320px;
    max-width: 350px;
  }
}
::v-deep .typeform {
  .n-typeform-cta {
    display: flex;
  }
  .valid-icon {
    margin: auto;
    margin-left: 0.5rem;
  }
}
#form {
  background-color: #0d4355;
  border-radius: 10px;
  border: 2px solid white;
}
#captcha {
  visibility: hidden;
}
</style> 
