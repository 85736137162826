export default {
  data () {
    return {
      dedupStatus: {
        checking: false,
        isDedup: false,
        checked: false
      }
    }
  },
  methods: {
    didChangeEmail () {
      var pattern = new RegExp(/^[_a-z0-9A-Z.\-_/+]+(\.[_a-z0-9A-Z]+)*@[a-z0-9-A-Z]+(\.[a-z0-9-A-Z]+)*(\.(arpa|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw))$/)
      if (this.signupForm.model.sEmail) {
        if (this.signupForm.model.sEmail.indexOf('@') > -1 && this.signupForm.model.sEmail.indexOf('.') > -1) {
          if (pattern.test(this.signupForm.model.sEmail)) {
            this.dedupEmail()
          }
        }
      }
    },
    suggestion () {
      this.$requestAPI.suggestion(this.signupForm.model.sEmail)
        .then(({ response, data }) => {
          this.blocked = data.data[0].blocked;

          if (data.data[0].changed) {
            this.suggestions = data.data[0].suggestions;
          } else {
            this.suggestions = [];
          }
        })
        .catch(console.log);
    },
    fillSuggestion (suggestion) {
      this.signupForm.model.sEmail = this.signupForm.model.sEmail.substring(0, this.signupForm.model.sEmail.indexOf('@')) + suggestion
      this.suggestions = []
      this.suggestion()
    },
    dedupEmail () {
      if (!this.dedupStatus.checking) {
        this.$requestAPI.dedupEmail(this.signupForm.model.sEmail)
          .then(({ response, data }) => {
            if ([1, 2, 3].indexOf(data.data[0].oOptins.iOptinMarque_2) > -1) {
              this.dedupStatus.checking = false
              this.dedupStatus.checked = true
              this.dedupStatus.isDedup = true
            } else {
              this.dedupStatus.checking = false
              this.dedupStatus.checked = true
              this.dedupStatus.isDedup = false
            }
          })
          .catch((error) => {
            // Test if error is an ApiError object
            if (error.name === 'ApiError') {
              if (error.response && error.response.status === 404) {
                this.dedupStatus.checking = false
                this.dedupStatus.checked = true
                this.dedupStatus.isDedup = false
              }
            }
          });
      }
    },
  }
} 